var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Project Users")]),_c('v-tab',[_vm._v("Pending Invites")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"depressed":"","rounded":"","color":"primary"},on:{"click":function($event){_vm.showInviteUserModal = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus-outline")]),_vm._v(" Invite User ")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('v-row',_vm._l((_vm.projectUsers),function(user){return _c('v-col',{key:user._id,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-0",attrs:{"width":"100%"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6 text-uppercase"},[_vm._v(" "+_vm._s(user.firstName)+" "+_vm._s(user.lastName)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.editProjectUser(user)}}},[_vm._v(" Edit ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.removeProjectUser(
														user._id
													)}}},[_vm._v(" Remove ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"text-subtitle-1"},[_c('p',{staticClass:"pr-2 black--text"},[_vm._v("Email:")]),_c('span',[_vm._v(" "+_vm._s(user.email)+" ")])]),_c('v-row',{staticClass:"text-subtitle-1"},[_c('p',{staticClass:"pr-2 black--text"},[_vm._v("Role:")]),_vm._l((user.roles),function(role,index){return _c('span',{key:role.index},[(
												index !=
												user.roles.length - 1
											)?_c('p',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name + ' ,')+" ")]):_c('p',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name)+" ")])])})],2)],1)],1)],1)}),1)],1),_c('v-tab-item',{attrs:{"transition":"fade-transition","reverse-transition":"fade-transition"}},[(_vm.pendingUserInvites.length > 0)?_c('v-row',_vm._l((_vm.pendingUserInvites),function(user){return _c('v-col',{key:user.id,attrs:{"cols":"4"}},[_c('v-card',{staticClass:"mb-0",attrs:{"width":"100%"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6 text-lowercase"},[_vm._v(" "+_vm._s(user.email)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.resendInvite(user.id)}}},[_vm._v(" Resend ")])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.revokeInvite(user.id)}}},[_vm._v(" Revoke ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"text-subtitle-1"},[_c('p',{staticClass:"pr-2 black--text"},[_vm._v(" Invited by: ")]),_c('span',[_vm._v(" "+_vm._s(user.invitedBy.firstName)+" "+_vm._s(user.invitedBy.lastName)+" ")])]),_c('v-row',{staticClass:"text-subtitle-1"},[_c('p',{staticClass:"pr-2 black--text"},[_vm._v("Role:")]),_vm._l((user.roles),function(role,index){return _c('span',{key:role.index},[(
												index !=
												user.roles.length - 1
											)?_c('p',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name + ' ,')+" ")]):_c('p',{staticClass:"text-lowercase"},[_vm._v(" "+_vm._s(role.name)+" ")])])})],2)],1)],1)],1)}),1):_c('v-row',[_c('NoData',{attrs:{"title":"No invites pending!","subtitle":"Invite more collaborators and collectively make this project a success!"}})],1)],1)],1),_c('InviteUser',{attrs:{"showInviteUserModal":_vm.showInviteUserModal},on:{"close":function($event){_vm.showInviteUserModal = false}}}),_c('EditUser',{attrs:{"show-edit-user-modal":_vm.showEditUserModal,"user":_vm.userToEdit},on:{"close":function($event){_vm.showEditUserModal = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
	<v-row justify="center">
		<v-dialog
			v-model="showModal"
			@click:outside="reset()"
			max-width="600px"
		>
			<v-card class="mb-0 pa-6">
				<div class="d-flex justify-space-between align-start mb-5">
					<h1 class="text-h5">Invite Users</h1>
					<span class="mdi mdi-close close" @click="reset()"></span>
				</div>

				<v-form @submit.prevent="validateData">
					<v-row class="mx-0">
						<v-col cols="12" class="pa-0">
							<p class="mb-2 label">Email</p>
							<v-text-field
								placeholder="john@company.com"
								solo
								flat
								dense
								outlined
								v-model="userEmail"
								type="email"
								:rules="emailRules"
								:error-messages="errors.email"
								required
							></v-text-field>
						</v-col>

						<v-col cols="12" class="pa-0">
							<p class="mb-2 label">Roles</p>
							<v-select
								v-model="userRoles"
								:items="rolesName"
								chips
								label="Select the role/roles"
								multiple
								solo
								outlined
								dense
								flat
								:error-messages="errors.roles"
							>
							</v-select>
						</v-col>
					</v-row>

					<div class="d-flex justify-end">
						<v-btn
							type="submit"
							outlined
							class="cancel mr-4"
							@click="reset()"
						>
							Cancel
						</v-btn>

						<v-btn
							type="submit"
							@click.prevent="validateData()"
							color="success"
						>
							Invite
						</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		showInviteUserModal: {
			type: Boolean,
			require: true,
		},
	},
	data() {
		return {
			showModal: false,
			rolesName: [],
			roles: [],
			emailRules: [
				(v) => !!v || 'E-mail is required',
				(v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
			],
			errors: {
				email: '',
				roles: '',
			},
		}
	},
	created() {
		this.showModal = this.showInviteUserModal
		this.getRoles()
	},
	watch: {
		showInviteUserModal(value) {
			this.showModal = value
		},
		userEmail() {
			this.errors.email = ''
		},
		userRoles() {
			this.errors.roles = ''
		},
	},
	computed: {
		userEmail: {
			get() {
				return this.$store.state.user.userEmail
			},
			set(value) {
				this.saveUserData({ userEmail: value })
			},
		},
		userRoles: {
			get() {
				return this.$store.state.user.userRoles
			},
			set(roles) {
				this.saveUserData({ userRoles: roles })
			},
		},
	},
	methods: {
		getRoles() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/roles`,
			}).then((response) => {
				this.roles = response.data.data
				this.rolesName = response.data.data.map((item) => {
					return item.name
				})
			})
		},
		saveUserData(data) {
			this.$store.commit('user/updateProjectUser', data)
		},
		validateData() {
			this.userEmail === ''
				? (this.errors.email = 'Email Address is Required')
				: (this.errors.email = '')
			this.userRoles.length === 0
				? (this.errors.roles =
						'Please select a specified role for the user')
				: (this.errors.roles = '')
			if (this.userEmail !== '' && this.userRoles.length > 0) {
				this.inviteUser()
			}
		},
		inviteUser() {
			let selectedRoles = []
			for (let i = 0; i < this.userRoles.length; i++) {
				for (let j = 0; j < this.roles.length; j++) {
					if (this.userRoles[i] == this.roles[j].name)
						selectedRoles.push({
							roleId: this.roles[j].id,
							name: this.roles[j].name,
						})
				}
			}
			let data = {
				email: this.userEmail,
				roles: selectedRoles,
			}
			this.$store.dispatch('user/inviteUser', data)
			this.reset()
		},
		reset() {
			this.$store.commit('user/reset')
			this.showModal = false
			this.$emit('close')
		},
	},
}
</script>

<style scoped>
.headline {
	font-weight: 400 !important;
}
.card-actions .icon {
	font-size: 17px;
}
.label {
	font-size: 15px;
	color: black;
}
.cancel {
	border: 1px solid #888585;
	color: #888585;
}
</style>

<template>
	<v-container fluid>
		<v-card>
			<v-toolbar flat>
				<v-tabs v-model="tab">
					<v-tab>Project Users</v-tab>
					<v-tab>Pending Invites</v-tab>
				</v-tabs>

				<v-spacer></v-spacer>

				<v-btn
					depressed
					rounded
					color="primary"
					@click="showInviteUserModal = true"
					class="text-capitalize"
				>
					<v-icon left>mdi-account-plus-outline</v-icon>
					Invite User
				</v-btn>
			</v-toolbar>

			<v-tabs-items v-model="tab">
				<!-- Project Users -->
				<v-tab-item
					transition="fade-transition"
					reverse-transition="fade-transition"
				>
					<v-row>
						<v-col
							cols="4"
							v-for="user in projectUsers"
							:key="user._id"
						>
							<v-card class="mb-0" width="100%">
								<v-card-title>
									<span class="text-h6 text-uppercase">
										{{ user.firstName }}
										{{ user.lastName }}
									</span>

									<v-spacer></v-spacer>

									<v-menu bottom left>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<v-btn
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>
													mdi-dots-vertical
												</v-icon>
											</v-btn>
										</template>

										<v-list dense>
											<v-list-item link>
												<v-list-item-title
													@click="
														editProjectUser(user)
													"
												>
													Edit
												</v-list-item-title>
											</v-list-item>

											<v-list-item link>
												<v-list-item-title
													@click="
														removeProjectUser(
															user._id
														)
													"
												>
													Remove
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-card-title>

								<v-card-text>
									<v-row class="text-subtitle-1">
										<p class="pr-2 black--text">Email:</p>
										<span>
											{{ user.email }}
										</span>
									</v-row>

									<v-row class="text-subtitle-1">
										<p class="pr-2 black--text">Role:</p>
										<span
											v-for="(role, index) in user.roles"
											:key="role.index"
										>
											<p
												v-if="
													index !=
													user.roles.length - 1
												"
												class="text-lowercase"
											>
												{{ role.name + ' ,' }}
											</p>
											<p v-else class="text-lowercase">
												{{ role.name }}
											</p>
										</span>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-tab-item>

				<!-- Pending Users -->
				<v-tab-item
					transition="fade-transition"
					reverse-transition="fade-transition"
				>
					<v-row v-if="pendingUserInvites.length > 0">
						<v-col
							cols="4"
							v-for="user in pendingUserInvites"
							:key="user.id"
						>
							<v-card class="mb-0" width="100%">
								<v-card-title>
									<span class="text-h6 text-lowercase">
										{{ user.email }}
									</span>

									<v-spacer></v-spacer>

									<v-menu bottom left>
										<template
											v-slot:activator="{ on, attrs }"
										>
											<v-btn
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>
													mdi-dots-vertical
												</v-icon>
											</v-btn>
										</template>

										<v-list dense>
											<v-list-item link>
												<v-list-item-title
													@click="
														resendInvite(user.id)
													"
												>
													Resend
												</v-list-item-title>
											</v-list-item>

											<v-list-item link>
												<v-list-item-title
													@click="
														revokeInvite(user.id)
													"
												>
													Revoke
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</v-card-title>

								<v-card-text>
									<v-row class="text-subtitle-1">
										<p class="pr-2 black--text">
											Invited by:
										</p>
										<span>
											{{ user.invitedBy.firstName }}
											{{ user.invitedBy.lastName }}
										</span>
									</v-row>

									<v-row class="text-subtitle-1">
										<p class="pr-2 black--text">Role:</p>
										<span
											v-for="(role, index) in user.roles"
											:key="role.index"
										>
											<p
												v-if="
													index !=
													user.roles.length - 1
												"
												class="text-lowercase"
											>
												{{ role.name + ' ,' }}
											</p>
											<p v-else class="text-lowercase">
												{{ role.name }}
											</p>
										</span>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>

					<v-row v-else>
						<NoData
							title="No invites pending!"
							subtitle="Invite more collaborators and collectively make this project a success!"
						></NoData>
					</v-row>
				</v-tab-item>
			</v-tabs-items>

			<!-- Invite New User -->
			<InviteUser
				:showInviteUserModal="showInviteUserModal"
				@close="showInviteUserModal = false"
			></InviteUser>

			<!-- Edit User -->
			<EditUser
				:show-edit-user-modal="showEditUserModal"
				:user="userToEdit"
				@close="showEditUserModal = false"
			></EditUser>
		</v-card>
	</v-container>
</template>

<script>
import InviteUser from './InviteUser'
import EditUser from './EditUser'
import NoData from '../../components/NoData'

export default {
	components: {
		InviteUser,
		EditUser,
		NoData,
	},
	data() {
		return {
			showInviteUserModal: false,
			showEditUserModal: false,
			userToEdit: {},
			tab: 0,
		}
	},
	created() {
		this.$emit('domain', 'user')
		this.$store.dispatch('user/getProjectUserListing')
		this.$store.dispatch('user/getPendingUserInviteListing')
	},
	destroyed() {
		this.$emit('tabs', [])
	},
	computed: {
		projectUsers: {
			get() {
				return this.$store.state.user.projectUsers
			},
		},
		pendingUserInvites: {
			get() {
				return this.$store.state.user.pendingUserInvites
			},
		},
	},
	methods: {
		editProjectUser(user) {
			this.userToEdit = user
			this.showEditUserModal = true
		},
		async removeProjectUser(id) {
			await this.$store.dispatch('user/removeProjectUser', id)
		},

		async revokeInvite(id) {
			await this.$store.dispatch('user/removePendingUserInvite', id)
		},

		async resendInvite(id) {
			await this.$store.dispatch('user/resendProjectInvite', id)
		},
	},
}
</script>

<style scoped>
.container,
.v-card {
	height: 100%;
}
</style>

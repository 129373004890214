<template>
	<v-row justify="center">
		<v-dialog
			v-model="showModal"
			max-width="600px"
			@click:outside="reset()"
		>
			<v-card class="mb-0 pa-6">
				<div class="d-flex justify-space-between align-start mb-5">
					<h1 class="text-h5">Edit User</h1>
					<span class="mdi mdi-close close" @click="reset()"></span>
				</div>

				<v-form @submit.prevent="validateData">
					<v-row class="mx-0">
						<v-col cols="12" class="pa-0">
							<p class="ma-0 mb-2">Email</p>
							<v-text-field
								placeholder="Add the email address of the user to be invited"
								solo
								flat
								dense
								outlined
								:value="userEmail"
								disabled
								type="email"
								required
							></v-text-field>
						</v-col>

						<v-col cols="12" class="pa-0">
							<p class="ma-0 mb-2">Roles</p>
							<v-select
								v-model="userRoles"
								:items="rolesName"
								chips
								label="Select the role/roles"
								multiple
								solo
								outlined
								dense
								flat
								:error-messages="errors.roles"
							></v-select>
						</v-col>
					</v-row>

					<div class="d-flex justify-end">
						<v-btn
							type="submit"
							outlined
							class="cancel mr-4"
							@click="reset()"
						>
							Cancel
						</v-btn>

						<v-btn
							type="submit"
							@click.prevent="validateData()"
							color="success"
						>
							Save
						</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		showEditUserModal: {
			type: Boolean,
			required: true,
		},
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			userEmail: '',
			userRoles: [],
			roles: [],
			rolesName: [],
			showModal: false,
			errors: {
				roles: '',
			},
		}
	},
	created() {
		this.getRoles()
	},
	watch: {
		showEditUserModal(value) {
			this.showModal = value
		},
		userRoles() {
			this.errors.roles = ''
		},
		user: function () {
			if (this.user) {
				if (this.user.roles) {
					this.userRoles = this.user.roles.map((role) => role.name)
					this.userEmail = this.user.email
					this.showModal = this.showEditUserModal
				}
			}
		},
	},
	methods: {
		getRoles() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/project/${projectId}/roles`,
			}).then((response) => {
				this.roles = response.data.data
				this.rolesName = response.data.data.map((item) => {
					return item.name
				})
			})
		},
		saveUserData(data) {
			this.$store.commit('user/updateProjectUser', data)
		},
		validateData() {
			this.userRoles.length === 0
				? (this.errors.roles =
						'Please select a specified role for the user')
				: (this.errors.roles = '')
			if (this.userRoles.length > 0) {
				this.editUser()
			}
		},
		editUser() {
			const userId = this.user._id

			let selectedRoles = []
			for (let i = 0; i < this.userRoles.length; i++) {
				for (let j = 0; j < this.roles.length; j++) {
					if (this.userRoles[i] == this.roles[j].name)
						selectedRoles.push(this.roles[j].id)
				}
			}

			this.$store.dispatch('user/editProjectUser', {
				id: userId,
				roles: selectedRoles,
			})

			this.reset()
		},
		reset() {
			// this.$store.commit('user/reset')
			this.userEmail = ''
			this.userRoles = []
			this.showModal = false
			this.$emit('close')
		},
	},
}
</script>

<style scoped>
.cancel {
	border: 1px solid #888585;
	color: #888585;
}
</style>
